import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { CopyToClipboard } from "react-copy-to-clipboard";

import btcicon from "../../../icons/crypto/btc.svg";
import ethicon from "../../../icons/crypto/eth.svg";
import usdticon from "../../../icons/crypto/usdt.svg";

import QRCode from "react-qr-code";

import { useTranslation } from "react-i18next";
import Loader from '../../pages/Loader';
import axios from 'axios';
import Snackbars from '../../pages/Snackbars';
import validator from 'validator';

export const Wallets_Deposit_Transaction = (props) => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");

    const [coincode, setCoincode] = useState("");
    const [coinname, setCoinname] = useState("");
    const [walletaddress, setWalletaddress] = useState("");
    const [total, setTotal] = useState(0);
    const [available, setAvailable] = useState(0);
    //const [pending, setPending] = useState(0);
    const [icon, setIcon] = useState(0);

    const [displaynote, setdisplaynote] = useState("");


    const [fundamount, setfundamount] = useState("");
    const [fundamount_error, setfundamount_error] = useState("");
    const [fundinfo, setfundinfo] = useState("");
    const [fundinfo_error, setfundinfo_error] = useState("");



    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        console.log(window.sessionStorage.getItem("depoidfk"));
        if (window.sessionStorage.getItem("depoidfk")) {
            var jsonpar = {
                user_id: window.sessionStorage.getItem("user_id"),
                depoid: window.sessionStorage.getItem("depoidfk")
            }
            window.sessionStorage.removeItem("depoidfk")
        } else {
            var jsonpar = {
                user_id: window.sessionStorage.getItem("user_id"),
                depoid: props.location.state
            }
        }


        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + '../settings/GetDepositDetails', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setLoader(false);

                    if (res.data.coincode === "BTC") {
                        setCoinname("Bitcoin");
                        setIcon(btcicon);
                    } else if (res.data.coincode === "ETH") {
                        setCoinname("Ethereum");
                        setIcon(ethicon);
                    }
                    else if (res.data.coincode === "USDT") {
                        setCoinname("USDT");
                        setIcon(usdticon);
                    }
                    setCoincode(res.data.coincode);
                    setWalletaddress(res.data.coinaddress);
                    setTotal(res.data.walletbalance);
                    setAvailable(res.data.available);
                    //setPending(res.data.pending);
                    setdisplaynote(res.data.note);
                } else {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }
            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }


    const validateFundAmount = (value) => {
        setfundamount(value);
        if (validator.isNumeric(value)) {
            setfundamount_error("");
            return true;
        } else {
            setfundamount_error("Please Enter Amount");
            return false;
        }
    }

    const validateFundInfo = (value) => {
        setfundinfo(value);
        if (!validator.isEmpty(value)) {
            setfundinfo_error("");
            return true;
        } else {
            setfundinfo_error("Please Enter Valid Fund Information");
            return false;
        }
    }

    const clickclip = () => {
        setSuccess(true);
        setSuccessMsg(t("CRYPTOTRANSACTION.COPIEDMSG"));
        setTimeout(() => {
            setSuccess(false);
        }, 6000);
    }
    return (
        <>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-header page-title-space">
                        <h2 className="text-black font-w600 mb-0">{coinname} {t("CRYPTOTRANSACTION.DEPOSIT_TITLE")}</h2>
                        <Link to="/crypto" className="back-to-page"> <i className="font-bold fa fa-angle-left"></i>&nbsp;&nbsp;{t("LINK.BACK")}</Link>
                    </div>
                </div>
            }

            <div className="row">
                {loader === true ?
                    <div>
                        <Loader open_close={loader} />
                    </div>
                    :
                    <div className="col-md-8">
                        <div className="card height-auto">
                            <div className="card-body">
                                <div className="media ai-icon">
                                    <span className="me-3 bgl-primary text-primary inner-icon">
                                        <img alt="" src={icon} className="bitimg" />
                                    </span>
                                    <div className="media-body">
                                        <h4 className="mb-1 coinname-new">{coinname}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="col-md-12">
                                    <div className="table-cstm-border">
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>{t("CRYPTOTRANSACTION.TOTAL_BALANCE")}</th>
                                                    <th>{t("CRYPTOTRANSACTION.AVAILABLE_BALANCE")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{parseFloat(total)} {coincode}</td>
                                                    <td>{parseFloat(available)} {coincode}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card height-auto">
                            <div className="card-body">
                                <div dangerouslySetInnerHTML={{ __html: displaynote }} />

                                <div className="col-md-12 mt-4 p-0">
                                    <div className="form-group position-relative">
                                        <h4>{coincode} Deposit Address</h4>
                                        <input type="text" className="form-control copy-text webkit-none" value={walletaddress} />
                                        <CopyToClipboard text={walletaddress}>
                                            <div className='tooltip-box'>
                                                <div className="copyboard" style={{ top: "40px" }} title="Copy Address" onClick={clickclip}>
                                                    <span> <i className="fa fa-clipboard" aria-hidden="true"></i> </span>
                                                </div>
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>
                }

                {loader === true ?
                    <div>
                        <Loader open_close={loader} />
                    </div>
                    :

                    <div className="col-md-4">
                        <div className="card height-auto">
                            <div className="card-body p-4">
                                <h4 className="card-title mb-4">{t("CRYPTOTRANSACTION.WALLETQR")}</h4>
                                <QRCode className="img-fluid qr-logo" value={walletaddress} />
                            </div>
                        </div>
                    </div>
                }

            </div>
            <div className="row">

                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body p-4">
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label className="mb-2">Amount</label>
                                        <input type="text" className="form-control" placeholder="Please Enter Amount" onChange={(e) => validateFundAmount(e.target.value)} />
                                        {fundamount_error !== "" ? <label className='text-red'>{fundamount_error}</label> : ""}

                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label className="mb-2">Transaction hash</label>
                                        <input type="text" className="form-control" placeholder="Please Transaction hash" onChange={(e) => validateFundInfo(e.target.value)} />
                                        {fundinfo_error !== "" ? <label className='text-red'>{fundinfo_error}</label> : ""}
                                    </div>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <button className="btn btn-primary btn-rounded mr-2" >Submit</button>
                                    <button className="btn btn-light btn-rounded light">Cancel</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )

}
export default Wallets_Deposit_Transaction;