import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';

import card1 from '../../../images/card/v-card.png';
// import card2 from '../../../images/card/p-card.png';
import dualdot from '../../../images/dual-dot.png';

// import { useTranslation } from "react-i18next";
import Loader from '../../pages/Loader';
import axios from 'axios';
// import moment from 'moment';
// import validator from 'validator';
// import Snackbars from '../../pages/Snackbars';

export const RequestCard = () => {
    // const { t } = useTranslation();
    const history = useHistory();
    // const [error, setError] = useState(false);
    // const [error_msg, setErrorMsg] = useState("");
    // const [success, setSuccess] = useState(false);
    // const [success_msg, setSuccessMsg] = useState("");
    const [loader, setLoader] = useState(false);

    const [cardarray, setcardarray] = useState([]);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        const headers = {
            'Content-Type': 'application/json',
            'useraccesstoken': process.env.REACT_APP_USER_ACCESS_TOKEN,
            'userauthtoken': 'Bearer ' + window.sessionStorage.getItem("user_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + 'getCards', { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setcardarray(res.data);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const handleforward = async (value) => {
        if (value === "1") {
            history.push({
                pathname: '/physical-card',
                state: await value
            });
        } else {
            history.push({
                pathname: '/virtual-card',
                state: await value
            });
        }
    }

    return (
        <>
            <div className="card">
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">Request Card</h2>
                </div>
            </div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="row hidecardform">
                    {cardarray.length > 0 && cardarray.map((row) => (
                        <div className="col-md-5">
                            <div className="card">
                                <div className="card-body payment-bx tab-content p-4 recent-Transactions-bottom">
                                    <div className="card-bx mb-0">
                                        <img src={card1} className="img-fluid" alt="" />
                                        <div className="card-info text-white">
                                            <p className="mb-1">{row.card_name}</p>
                                            <h2 className="fs-30 text-white mb-sm-2 mb-3">$673,412.66</h2>
                                            <div className="d-flex align-items-center justify-content-between mb-sm-2 mb-3">
                                                <img src={dualdot} alt="" className="dot-img" />
                                                <h4 className="fs-20 text-white mb-0">**** **** **** 1234</h4>
                                            </div>
                                            <div className="d-flex">
                                                <div className="mr-3">
                                                    <p className="fs-14 mb-1 op6">VALID THRU</p>
                                                    <span>08/21</span>
                                                </div>
                                                <div className="mr-3">
                                                    <p className="fs-14 mb-1 op6">CARD HOLDER</p>
                                                    <span>Franklin Jr.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 text-center">
                                        <p className="fs-26 text-black text-center mt-3 mb-3">{row.card_name}</p>
                                        <Link className="btn btn-primary mb-3 select-btn btn-rounded" to="#" onClick={(e) => handleforward(row.cardinfo_id)}>Select Card</Link>
                                    </div>
                                    <div className="col-xs-12">
                                        <ul className="benifits">
                                            <div dangerouslySetInnerHTML={{ __html: row.card_desc }} />
                                        </ul>
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="fees">
                                            <p className="mb-0"><strong>Joining Fees</strong></p>
                                            <p>{row.joining_fees_usd + '$'} , {row.joining_fees_eur + '€'}</p>

                                            <p className="mb-0"><strong>Annual Fees</strong></p>
                                            <p>{row.annual_fees_usd + '$'} , {row.annual_fees_eur + '€'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </>
    )

}
export default RequestCard;